'use client';

import Link from 'next/link';
import cs from 'classnames';
import { styled } from 'styled-components';

type TextLinkVariant = 'anchor' | 'link' | 'external';

type TextLinkProps = {
  /**
   * What href should this point to?
   */
  href: string;
  /**
   * What does the link say?
   */
  children: string;
  /**
   * Optional class name, nesc. for extending as a styled-component
   */
  className?: string;
  /**
   * Optional click event handler, using the same signature as
   * next/link
   */
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

/**
 * Determines link styling using fastest-first checks:
 * 1. Simple '#' check for page anchors (↓)
 * 2. Absolute URLs: external vs wistia links (↗ vs →)
 * 3. Relative URLs and invalid URLs default to internal (→)
 */
const computedVariant = (href: string): TextLinkVariant => {
  if (href.startsWith('#')) return 'anchor';
  if (!href.startsWith('http')) return 'link';
  
  try {
    const url = new URL(href);
    return url.hostname.includes('wistia') ? 'link' : 'external';
  } catch {
    return 'link';
  }
};

const TextLinkWrapper = styled(Link)`
  color: var(--global-link-color);
  text-decoration: underline transparent;
  transition: text-decoration-color 333ms ease-in-out;

  &::after {
    content: '→';
    display: inline-block;
    padding-inline-start: ${({ theme }) => theme.spacing['0.25']};
    transition: transform 250ms ease-in-out;
  }
  &.anchor::after {
    content: '↓';
  }
  &.external::after {
    content: '↗';
    transform: translate3d(0, 0.1875em, 0);
  }

  &:visited {
    color: var(--global-visited-color);
  }

  &:hover,
  &:active {
    color: var(--global-hover-color);
    text-decoration: underline currentcolor;

    &::after {
      transform: translateX(${({ theme }) => theme.spacing['0.25']});
    }
    &.anchor::after {
      transform: translateY(${({ theme }) => theme.spacing['0.25']});
    }
    // custom transform for external links to move the arrow up and to the right
    &.external::after {
      transform: translate3d(0.125em, -0.0625em, 0);
    }
  }
`;

const TextLink = ({
  href,
  children,
  className,
  onClick,
}: TextLinkProps) => {
  const variant = computedVariant(href);
  return (
    <TextLinkWrapper
      className={cs(className, variant)}
      href={href}
      onClick={onClick}
    >
      {`${children}`}
    </TextLinkWrapper>
  );
}
export default TextLink;

import { createGlobalStyle} from 'styled-components';
import { visuallyHiddenStyles } from '@lib/styles';


export const GlobalStyles = createGlobalStyle`
  @property --gradient-base-nw {
    syntax: "<color>";
    inherits: true;
    initial-value: hsla(0, 0%, 100%, 0); // intentionally transparent 
  }
  @property --gradient-base-ne {
    syntax: "<color>";
    inherits: true;
    initial-value: hsla(0, 0%, 100%, 0); // intentionally transparent 
  }
  @property --gradient-base-se {
    syntax: "<color>";
    inherits: true;
    initial-value: hsla(0, 0%, 100%, 0); // intentionally transparent
  }
  @property --gradient-base-sw {
    syntax: "<color>";
    inherits: true;
    initial-value: hsla(0, 0%, 100%, 0); // intentionally transparent
  }

  /* border-box ftw */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  /* Anything that has been anchored to should have extra scroll margin */
  :target {
    scroll-margin-block: 5ex;
  }

  :root {
    --global-bg-color: ${({ theme }) => theme.colors.white};
    --global-color: ${({ theme }) => theme.colors.blue800};
    --global-link-color: ${({ theme }) => theme.colors.blue500};
    --global-hover-color: ${({ theme }) => theme.colors.blue700};
    --global-visited-color: ${({ theme }) => theme.colors.purple700};
    --global-bg-image: none;

    /* The following two repetitions of the initial-value custom props are for Firefox, until it supports @property */
    --gradient-base-nw: hsla(0, 0%, 100%, 0);
    --gradient-base-se: hsla(0, 0%, 100%, 0);
    --gradient-base-ne: hsla(0, 0%, 100%, 0);
    --gradient-base-sw: hsla(0, 0%, 100%, 0);
  }

  html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    /* _Accessible_ anchor smooth scrolling, site-wide */
    scroll-behavior: smooth;
  }

  html, body {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: var(--font-inter);
    font-size:  ${({ theme }) => theme.typography.fontSize.bodyMd};
    font-weight: ${({ theme }) => theme.typography.fontWeight.body};
    line-height: ${({ theme }) => theme.typography.lineHeight.body};

    ${({ theme }) => theme.mq.minWidth.large} {
      font-size:  ${({ theme }) => theme.typography.fontSize.desktop.bodyMd};
    }
    background-color: var(--global-bg-color);
    background-image:
    radial-gradient(
      ellipse 100% 80% at 90% 90%,
      var(--gradient-base-se) 30%,
      transparent
    ),
    radial-gradient(
      ellipse 100% 90% at -10% 0%,
      var(--gradient-base-nw) 40%,
      transparent
    ),
    radial-gradient(
      ellipse at 100% 35%, 
      var(--gradient-base-ne),
      transparent 28%
    ), 
    radial-gradient(
      ellipse at 0% 75%, 
      var(--gradient-base-sw),
      transparent 28%
    ),
    var(--global-bg-image);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-y;
    background-position: top center;
    color: var(--global-color);
    transition-property: color, --gradient-base-nw, --gradient-base-ne, --gradient-base-se, --gradient-base-sw, background-color;
    transition-duration: 666ms, 666ms, 666ms, 666ms, 666ms, 333ms;
    transition-delay: 222ms, 222ms, 222ms, 222ms, 222ms, 111ms;
    transition-timing-function: ease-in-out;
  }

  body::before,
  body::after {
    content: '';
    display: inline-block;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  body::before {
    background-image: radial-gradient(
      ellipse at 0% 0%,
      var(--gradient-base-nw),
      transparent 50%
    ),
    radial-gradient(
      ellipse at 100% 0%,
      var(--gradient-base-ne),
      transparent 50%
    ),
    radial-gradient(
      ellipse at 0% 100%,
      var(--gradient-base-sw),
      transparent 50%
    ),
    radial-gradient(
      ellipse at 100% 100%,
      var(--gradient-base-se),
      transparent 50%
    );
  }

  body::after {
    animation: moveTopGradient 10s ease-in-out infinite alternate;
    background-image: radial-gradient(
        ellipse at 0% 0%,
        var(--gradient-base-sw),
        transparent 50%
      ),
      radial-gradient(
        ellipse at 100% 0%,
        var(--gradient-base-se),
        transparent 50%
      ),
      radial-gradient(
        ellipse at 0% 100%,
        var(--gradient-base-nw),
        transparent 50%
      ),
      radial-gradient(
        ellipse at 100% 100%,
        var(--gradient-base-ne),
        transparent 50%
      );
    background-size: 200% 200%;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    text-wrap: balance;
    margin: 0 0 ${({ theme }) => theme.spacing[1.5]};
  }

  h1 {
    font-family: var(--font-walsheim);
    font-size:  ${({ theme }) => theme.typography.fontSize.headingOne};
    font-weight: ${({ theme }) => theme.typography.fontWeight.headingOne};
    line-height: ${({ theme }) => theme.typography.lineHeight.headingOne};

    ${({ theme }) => theme.mq.minWidth.large} {
      font-size:  ${({ theme }) =>
        theme.typography.fontSize.desktop.headingOne};
    }
  }

  h2 {
    font-family: var(--font-walsheim);
    font-size:  ${({ theme }) => theme.typography.fontSize.headingTwo};
    font-weight: ${({ theme }) => theme.typography.fontWeight.headingTwo};
    line-height: ${({ theme }) => theme.typography.lineHeight.headingTwo};

    ${({ theme }) => theme.mq.minWidth.large} {
      font-size:  ${({ theme }) =>
        theme.typography.fontSize.desktop.headingTwo};
    }
  }

  h3 {
    font-family: var(--font-walsheim);
    font-size:  ${({ theme }) => theme.typography.fontSize.headingThree};
    font-weight: ${({ theme }) => theme.typography.fontWeight.headingThree};
    line-height: ${({ theme }) => theme.typography.lineHeight.headingThree};

     ${({ theme }) => theme.mq.minWidth.large} {
      font-size:  ${({ theme }) =>
        theme.typography.fontSize.desktop.headingThree};
    }
  }

  h4 {
    font-family: var(--font-walsheim);
    font-size:  ${({ theme }) => theme.typography.fontSize.headingFour};
    font-weight: ${({ theme }) => theme.typography.fontWeight.headingFour};
    line-height: ${({ theme }) => theme.typography.lineHeight.headingFour};

     ${({ theme }) => theme.mq.minWidth.large} {
      font-size:  ${({ theme }) =>
        theme.typography.fontSize.desktop.headingFour};
    }
  }

  h5 {
    font-family: var(--font-walsheim);
    font-size:  ${({ theme }) => theme.typography.fontSize.headingFive};
    font-weight: ${({ theme }) => theme.typography.fontWeight.headingFive};
    line-height: ${({ theme }) => theme.typography.lineHeight.headingFive};

    ${({ theme }) => theme.mq.minWidth.large} {
      font-size:  ${({ theme }) =>
        theme.typography.fontSize.desktop.headingFive};
    }
  }

  hr {
    color: var(--global-link-color);
    height: 1px;
  }
  h2 + hr {
    margin-block-start: -${({ theme }) => theme.spacing[1]};
  }

  p {
    margin: 0 0 ${({ theme }) => theme.spacing[1.5]};
  }
  /* 48px: Paragraph space between Super Title, H1 and any body style */
  h1 + p,
  p + h1 {
    margin-block-start: ${({ theme }) => theme.spacing[3]};
  }

  a {
    font-size: inherit;
    cursor: pointer;
    text-decoration: none;
  }

  a[class] {
    font-weight: ${({ theme }) => theme.typography.fontWeight.link};
    line-height: ${({ theme }) => theme.typography.lineHeight.link};
  }

  a:not([class]) {
    color: var(--global-link-color);
    text-decoration-skip-ink: auto;
    text-decoration: underline currentColor;
    transition: text-decoration 333ms ease-in-out;
  
    &:visited {
      color: var(--global-visited-color);
    }
    // https://css-tricks.com/remember-selectors-with-love-and-hate/
    &:hover, &:active {
      text-decoration-color: transparent;
      color: var(--global-hover-color);
    }
    
  }

  /* 48px: Space between any text type with an image in between */
  p + img:not([class]),
  p + picture:not([class]) {
    margin-block-start: ${({ theme }) => theme.spacing[3]};
    margin-block-end: ${({ theme }) => theme.spacing[3]};
  }

  blockquote {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  
  cite {
    font-style: normal;
  }

  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  textarea:not([rows]) {
    min-height: 10em
  }

  .visually-hidden {
    ${visuallyHiddenStyles}
  }

  @keyframes moveTopGradient {
      0% {
        background-position: 0% 50%;
      }
      10% {
        background-position: 0% 0%;
      }
      25% {
        background-position: 50% 0%;
      }
      40% {
        background-position: 100% 0%;
      }
      55% {
        background-position: 100% 50%;
      }
      70% {
        background-position: 100% 100%;
      }
      85% {
        background-position: 50% 100%;
      }
      100% {
        background-position: 0% 100%;
      }
    }
`;

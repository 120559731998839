/**
 * This should match, 1:1, the swatch list of colors in the Colors frame in
 * Figma Design Tokens page. (Except for the gradiants, which are composite
 * colors.)
 */
const swatches = {
  blue800: 'hsla(230, 100%, 10%, 1)',
  blue700: 'hsla(230, 50%, 27%, 1)',
  blue600: 'hsla(230, 66%, 36%, 1)',
  blue500: 'hsla(230, 78%, 53%, 1)',
  blue400: 'hsla(230, 100%, 71%, 1)',
  blue300: 'hsla(229, 100%, 80%, 1)',
  blue200: 'hsla(229, 100%, 90%, 1)',
  blue100: 'hsla(233, 100%, 98%, 1)',
  dropShadowBlue: 'hsla(229, 53%, 90%, 1)',
  dropShadowDarkBlue: 'hsla(230, 41%, 71%, 1)',
  green800: 'hsla(109, 100%, 10%, 1)',
  green700: 'hsla(109, 38%, 29%, 1)',
  green600: 'hsla(110, 75%, 30%, 1)',
  green500: 'hsla(110, 60%, 45%, 1)',
  green400: 'hsla(110, 61%, 62%, 1)',
  green300: 'hsla(110, 55%, 77%, 1)',
  green200: 'hsla(111, 100%, 93%, 1)',
  green100: 'hsla(111, 100%, 97%, 1)',
  dropShadowGreen: 'hsla(108, 45%, 77%, 1)',
  pink800: 'hsla(324, 100%, 15%, 1)',
  pink700: 'hsla(324, 53%, 26%, 1)',
  pink600: 'hsla(324, 85%, 47%, 1)',
  pink500: 'hsla(324, 100%, 63%, 1)',
  pink400: 'hsla(325, 100%, 77%, 1)',
  pink300: 'hsla(325, 100%, 89%, 1)',
  pink200: 'hsla(325, 100%, 95%, 1)',
  pink100: 'hsla(326, 100%, 99%, 1)',
  dropShadowPink: 'hsla(324, 54%, 89%, 1)',
  orange800: 'hsla(14, 100%, 15%, 1)',
  orange700: 'hsla(14, 82%, 28%, 1)',
  orange600: 'hsla(14, 90%, 37%, 1)',
  orange500: 'hsla(14, 78%, 46%, 1)',
  orange400: 'hsla(14, 100%, 63%, 1)',
  orange300: 'hsla(14, 100%, 75%, 1)',
  orange200: 'hsla(14, 100%, 86%, 1)',
  orange100: 'hsla(9, 100%, 99%, 1)',
  dropShadowOrange: 'hsla(14, 30%, 86%, 1)',
  purple800: 'hsla(284, 100%, 15%, 1)',
  purple700: 'hsla(285, 51%, 36%, 1)',
  purple600: 'hsla(285, 85%, 47%, 1)',
  purple500: 'hsla(285, 100%, 68%, 1)',
  purple400: 'hsla(285, 91%, 78%, 1)',
  purple300: 'hsla(285, 100%, 90%, 1)',
  purple200: 'hsla(286, 100%, 95%, 1)',
  purple100: 'hsla(285, 100%, 98%, 1)',
  dropShadowPurple: 'hsla(285, 58%, 90%, 1)',
  yellow800: 'hsla(62, 100%, 15%, 1)',
  yellow700: 'hsla(61, 98%, 24%, 1)',
  yellow600: 'hsla(61, 83%, 34%, 1)',
  yellow500: 'hsla(61, 77%, 42%, 1)',
  yellow400: 'hsla(61, 72%, 50%, 1)',
  yellow300: 'hsla(61, 84%, 52%, 1)',
  yellow200: 'hsla(61, 78%, 75%, 1)',
  yellow100: 'hsla(60, 100%, 97%, 1)',
  dropShadowYellow: 'hsla(61, 48%, 75%, 1)',
  white: 'hsla(0, 0%, 100%, 1)',
  grey100: 'hsla(230, 5%, 95%, 1)',
  grey200: 'hsla(230, 5%, 88%, 1)',
  grey300: 'hsla(230, 4%, 80%, 1)',
  grey400: 'hsla(230, 5%, 65%, 1)',
  grey500: 'hsla(230, 5%, 50%, 1)',
  grey600: 'hsla(230, 5%, 40%, 1)',
  grey700: 'hsla(230, 4%, 25%, 1)',
  grey800: 'hsla(230, 5%, 15%, 1)',
  black: 'hsla(230, 8%, 5%, 1)',
};

export const gradients = {
  purpleBlue: {
    ne: null,
    se: swatches.blue300,
    sw: null,
    nw: swatches.purple300,
  },
  fourWayMoving: {
    ne: swatches.blue400,
    se: swatches.purple400,
    sw: swatches.orange400,
    nw: swatches.pink400,
  },
  customBlue: {
    ne: 'hsla(193, 61%, 59%, 1)', // Custom color for Webinar Wizard
    se: null,
    sw: 'hsla(193, 61%, 59%, 1)', // Custom color for Webinar Wizard
    nw: null,
  },
  blueGreen: {
    ne: swatches.green300,
    se: null,
    sw: null,
    nw: swatches.blue300,
  },
};

export const colorSchemes = {
  blue: {
    bg: swatches.blue200,
    fg: swatches.blue800,
    link: swatches.blue500,
    hover: swatches.blue700,
    visited: swatches.purple800,
  },
  blueLight: {
    bg: swatches.blue100,
    fg: swatches.blue800,
    link: swatches.blue500,
    hover: swatches.blue700,
    visited: swatches.purple700,
  },
  blueDark: {
    bg: swatches.blue800,
    fg: swatches.white,
    link: swatches.blue400,
    hover: swatches.white,
    visited: swatches.purple200,
  },
  green: {
    bg: swatches.green300,
    fg: swatches.green800,
    link: swatches.green700,
    hover: swatches.green800,
    visited: swatches.purple800,
  },
  greenLight: {
    bg: swatches.green100,
    fg: swatches.green800,
    link: swatches.green600,
    hover: swatches.green800,
    visited: swatches.purple700,
  },
  greenDark: {
    bg: swatches.green800,
    fg: swatches.white,
    link: swatches.green400,
    hover: swatches.white,
    visited: swatches.purple200,
  },
  pink: {
    bg: swatches.pink300,
    fg: swatches.pink800,
    link: swatches.pink700,
    hover: swatches.pink800,
    visited: swatches.purple800,
  },
  pinkLight: {
    bg: swatches.pink100,
    fg: swatches.pink800,
    link: swatches.pink600,
    hover: swatches.pink800,
    visited: swatches.purple700,
  },
  pinkDark: {
    bg: swatches.pink800,
    fg: swatches.white,
    link: swatches.pink400,
    hover: swatches.white,
    visited: swatches.purple200,
  },
  orange: {
    bg: swatches.orange200,
    fg: swatches.orange800,
    link: swatches.orange700,
    hover: swatches.orange800,
    visited: swatches.purple800,
  },
  orangeLight: {
    bg: swatches.orange100,
    fg: swatches.orange800,
    link: swatches.orange400,
    hover: swatches.orange800,
    visited: swatches.purple700,
  },
  orangeDark: {
    bg: swatches.orange800,
    fg: swatches.white,
    link: swatches.orange400,
    hover: swatches.white,
    visited: swatches.purple200,
  },
  purple: {
    bg: swatches.purple300,
    fg: swatches.purple800,
    link: swatches.purple700,
    hover: swatches.purple800,
    visited: swatches.blue600,
  },
  purpleLight: {
    bg: swatches.purple100,
    fg: swatches.purple800,
    link: swatches.purple600,
    hover: swatches.purple800,
    visited: swatches.blue700,
  },
  purpleDark: {
    bg: swatches.purple800,
    fg: swatches.white,
    link: swatches.purple400,
    hover: swatches.white,
    visited: swatches.blue200,
  },
  yellow: {
    bg: swatches.yellow200,
    fg: swatches.yellow800,
    link: swatches.yellow800,
    hover: swatches.black,
    visited: swatches.purple800,
  },
  yellowLight: {
    bg: swatches.yellow100,
    fg: swatches.yellow800,
    link: swatches.yellow800,
    hover: swatches.black,
    visited: swatches.purple700,
  },
  yellowDark: {
    bg: swatches.yellow800,
    fg: swatches.white,
    link: swatches.yellow400,
    hover: swatches.white,
    visited: swatches.purple200,
  },
  light: {
    bg: swatches.white,
    fg: swatches.blue800,
    link: swatches.blue500,
    hover: swatches.blue700,
    visited: swatches.purple700,
  },
  dark: {
    bg: swatches.grey800,
    fg: swatches.white,
    link: swatches.blue500,
    hover: swatches.white,
    visited: swatches.purple200,
  },
};

export const colors = { ...swatches };
